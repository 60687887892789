<template>
<div>
    <b-container fluid>
        <div class="row">
            <div class="col-md-12">
                <div class="card card-primary card-outline">
                    <div class="card-header">
                        <div class="card-title">
                            <h4><i class="fa fa-briefcase"></i> Kunden</h4>
                        </div>
                        <div class="clearfix card-tools">
                            <div class="row">
                                <div class="col-md-12">
                                    

                                    <nav class="py-0 bg-white navbar navbar-expand navbar-light justify-content-end">
                                        <ul class="navbar-nav">  
                                            <li class="nav-item mr-1">
                                                <div class="input-group input-group-sm mt-0">
                                                    <input type="text" class="form-control" placeholder="Suchen"  @keyup.enter="loadCustomers" :value="search" @input="$store.commit('customers/changeSearch', $event.target.value)" />
                                                    <button type="submit" class="btn btn-default btn-sm" @click.prevent="loadCustomers">
                                                        <i class="fas fa-search"></i>
                                                    </button>
                                                    <button v-if="this.search != ''" type="button" class="ml-1 btn btn-danger btn-sm" @click="resetSearch">
                                                        <i class="fas fa-times"></i>
                                                    </button>
                                                </div>
                                            </li>
                                            <li class="nav-item">
                                                <button type="button" class="mr-1 btn btn-success btn-sm" @click="loadCustomers">
                                                    <i class="fas fa-sync"></i>
                                                </button>
                                            </li>
                                            <li class="nav-item">
                                                <router-link :to="{name: 'customers.create'}" class="btn btn-primary btn-sm">
                                                    <i class="fas fa-plus"></i>
                                                </router-link>
                                            </li>
                                            <li class="nav-item">
                                                <button type="button" class="btn btn-sm btn-secondary ml-1" data-widget="control-sidebar"><i class="fas fa-filter"></i></button>
                                            </li>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-body" >
                        <div class="table-responsive">
                        <table class="table table-hover table-sm">
                                    <thead>
                                        <tr>
                                            <th>
                                                Firma                                      
                                            </th>
                                            <th>
                                                Kundengruppe
                                            </th>
                                            <th><a href="#" @click.prevent="changeSort('active')">Aktiv</a>
                                                <span v-if="this.params.sort_field == 'active' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'active' && this.params.sort_direction == 'desc'" >&darr;</span></th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('customernumber')">Kundenummer</a>
                                                <span v-if="this.params.sort_field == 'customernumber' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'customernumber' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('email')">E-Mail</a>
                                                <span v-if="this.params.sort_field == 'email' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'email' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                Firma
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('first_name')">Vorname</a>
                                                <span v-if="this.params.sort_field == 'first_name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'first_name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('last_name')">Nachname</a>
                                                <span v-if="this.params.sort_field == 'last_name' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'last_name' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('zipcode')">PLZ</a>
                                                <span v-if="this.params.sort_field == 'zipcode' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'zipcode' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                <a href="#" @click.prevent="changeSort('city')">Stadt</a>
                                                <span v-if="this.params.sort_field == 'city' && this.params.sort_direction == 'asc'" >&uarr;</span>
                                                <span v-if="this.params.sort_field == 'city' && this.params.sort_direction == 'desc'" >&darr;</span>
                                            </th>
                                            <th>
                                                Anmeldedatum
                                            </th>
                                            <th>
                                                Letzter Login
                                            </th>
                                            <th v-if="params.onlyTrashed == true">
                                                gelöscht am
                                            </th>
                                            <th style="width: 10%">Aktionen</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="customer in customers.data" :key="customer.id" :class="customer.deleted_at != null ? 'table-danger' : null">
                                            <td>{{ customer.company.name }}</td>
                                            <td>{{ customer.group.description }}</td>
                                            <td>
                                                <i v-if="customer.active == 1" class="fas fa-check"></i>
                                                <i v-else class="fas fa-times"></i>
                                            </td>
                                            <td><span class="badge badge-pill badge-dark" >{{ customer.customernumber}}</span></td>
                                            <td>{{ customer.email}}</td>
                                            <td>{{ customer.billing_address.company }}</td>
                                            <td>{{ customer.first_name }}</td>
                                            <td>{{ customer.last_name }}</td>
                                            <td>{{ customer.billing_address.zipcode }}</td>
                                            <td>{{ customer.billing_address.city }}</td>
                                            <td>{{ customer.created_at | formatDate }}</td>
                                            <td>{{ customer.lastlogin | formatDateTime }}</td>
                                            <td v-if="params.onlyTrashed == true">{{ customer.deleted_at | formatDateTime }}</td>
                                            <td>
                                                <template v-if="params.onlyTrashed == false">
                                                    <router-link class="mr-1 btn btn-secondary btn-xs" :to="{name: 'orders.create', params: {id: customer.id}}" ><i class="fas fa-fw fa-clipboard-list"></i></router-link>
                                                    <router-link class="mr-1 btn btn-success btn-xs" :to="{name: 'customers.show', params: {id: customer.id}}" v-if="$auth.check('customers.show') && !$auth.check('customers.edit')"><i class="fas fa-fw fa-eye"></i></router-link>
                                                    <router-link class="mr-1 btn btn-warning btn-xs" :to="{name: 'customers.details', params: {id: customer.id}}" v-if="$auth.check('customers.edit')"><i class="fas fa-fw fa-edit"></i></router-link>
                                                    <b-button size="xs" @click="deleteCustomer(customer.id)" variant="danger" v-if="$auth.check('customers.destroy')"><i class="fas fa-fw fa-trash"></i></b-button>
                                                </template>
                                                <b-button  size="xs" @click="restoreCustomer(customer.id)" variant="danger" v-if="$auth.check('customers.destroy') && params.onlyTrashed == true"><i class="fas fa-fw fa-trash-restore"></i></b-button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                </div>
                    </div>

                    <div class="card-footer">
                        <pagination class="float-left" :data="customers" @pagination-change-page="loadCustomers" :limit="3"></pagination>
                        
                        <select class="float-right form-control-sm" v-model="params.per_page">
                            <option value="25">25 Kunden</option>
                            <option value="50">50 Kunden</option>
                            <option value="75">75 Kunden</option>
                            <option value="100">100 Kunden</option>
                            <option value="125">125 Kunden</option>
                            <option value="150">150 Kunden</option>
                        </select>
                        <p v-if="notEmptyObject(customers)" class="float-right mr-2">Anzeige Eintrag {{ customers.meta.from }} - {{ customers.meta.to }} von {{ customers.meta.total }}</p>
                    </div>
                </div>
            </div>
        </div>
        
    </b-container>
    
    <aside class="control-sidebar control-sidebar-light">
        <div class="p-3">
            <h3>Filteroptionen</h3>
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Firma:</label>
                        <select v-model="params.company_id" class="form-control form-control-sm">
                            <option :value="''">-- Filter Firma --</option>
                            <option v-for="company in companies" :key="company.id" :value="company.id">{{ company.name }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Kundengruppe:</label>
                        <select v-model="params.group_id" class="form-control form-control-sm">
                            <option :value="''">-- Kundengruppe --</option>
                            <option v-for="group in groups" :key="group.id" :value="group.id">{{ group.description }}</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12">
                    <div class="form-group">
                        <label>Gelöschte Kunden</label>
                        <select v-model="params.onlyTrashed" class="form-control form-control-sm">
                            <option :value="false">Nein</option>
                            <option :value="true">Ja</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="row mt-2">
                <div class="col-md-12 text-center">
                    <div class="form-group">
                        <button type="button" class="btn btn-sm btn-secondary" @click="resetFilter">Filter zurücksetzen</button>
                    </div>
                </div>
            </div>

        </div>  
    </aside>

    </div>

</template>

<script>
import {mapState} from 'vuex';
export default {
    data(){
        return {
            customers: {},
            companies: [],
            groups: {},
            // params: {
            //     sort_field: 'created_at',
            //     sort_direction: 'desc',
            //     per_page: 25,
            // },
            // search: '',
        }
    },

    computed: {
        selectAll: {
            get: function () {
                return this.articles.data ? this.selectedArticles.length == this.articles.data.length : false;
            },
            set: function (value) {
                var selected = [];

                if(value) {
                    this.articles.data.forEach(function (article) {
                        selected.push(article.id);
                    });
                }

                this.selectedArticles = selected;
            }
        },
        ...mapState('customers', ['params', 'search', 'page'])
    },

    watch: {

        params: {
            handler () {
                this.loadCustomers();
            },
            deep: true
        },
    },

    methods:{
        resetSearch(){
            this.$store.commit('customers/changeSearch', '');
            this.loadCustomers();
        },

        resetFilter(){
            this.params.company_id = '';
            this.params.group_id = '';
        },

        changeSort(field) {
            if(this.params.sort_field === field) {
                this.params.sort_direction = this.params.sort_direction == 'asc' ? 'desc' : 'asc';
            }
            else{
                this.params.sort_field = field;
                this.params.sort_direction = 'asc';
            }
        },
        
        handleCustomFilter(value){
            console.log(value);
        },

        onRowDoubleClick(params) {
            // params.row - row object 
            // params.pageIndex - index of this row on the current page.
            // params.selected - if selection is enabled this argument 
            // indicates selected or not
            // params.event - click event
            //console.log(params.row);
            this.$router.push({name: 'customers.details', params: {id: params.row.id}})
        }, 
        deleteCustomer(id){
            this.$swal({
                title: "Möchtest du den Kunden wirklich löschen?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                confirmButtonText: `Ja, löschen!!!`,
                denyButtonText: `Abbrechen`,
            })
            .then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                    //Send request to the Server
                    this.axios
                        .delete("/customers/" + id)
                        .then(() => {
                            this.$swal({
                                icon: "success",
                                title: "Kunde erfolgreich gelöscht!",
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 2000,
                                timerProgressBar: true,
                                });
                            this.loadCustomers();
                        })
                        .catch(() => {
                            this.$swal({
                                icon: "error",
                                title: "Oops...",
                                text: "Da ist etwas schief gelaufen",
                            });
                        });
                }
            });
        },

        loadCustomerGroups(){
            this.axios
                .get("/customer-groups")
                .then((response) => {
                    this.groups = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        restoreCustomer(id) {
            this.axios.post('/customers/restore/' + id)
                .then(() => {
                    this.$swal({
                        icon: "success",
                        title: "Kunde erfolgreich wiederhergestellt!",
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 2000,
                        timerProgressBar: true,
                        });
                    this.loadCustomers();
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Der Kunde konnte nicht wiederhergestellt werden",
                    });
                });
        },
        loadCompanies(){
            this.axios
                .get("/companies")
                .then((response) => {
                    this.companies = response.data.data;
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },

        loadCustomers(page){
            if(page == undefined)
            {
                page = this.page 
            }
            this.axios
                .get("/customers",{
                    params: {
                        page: page,
                        search: this.search,
                        ...this.params
                    }
                })
                .then((response) => {
                    this.customers = response.data;
                    this.$store.commit('customers/setPage', page);
                })
                .catch(() => {
                    this.$swal({
                        icon: "error",
                        title: "Oops...",
                        text: "Da ist etwas schief gelaufen",
                    });
                });
        },
    },

    created() {
        this.loadCustomers();
        this.loadCompanies();
        this.loadCustomerGroups();
    }
  }
</script>

<style>

</style>